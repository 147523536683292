import fb from 'firebase/app';
import { DateTime } from 'luxon';

export const getDateAsString = (timestamp?: number): string => {
  const date = timestamp ? new Date(timestamp) : new Date();
  const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const mm =
    date.getMonth() + 1 < 10
      ? '0' + (Number(date.getMonth()) + 1)
      : date.getMonth() + 1;
  const yyyy = date.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

export const transformStringToTimestamp = (payload: string) => {
  const [year, month, day] = payload.split('-');
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  return date.getTime();
};

/**
 * @param date yyyy-mm-dd
 * @returns dd-mm-yyyy
 */
export const ISOdateToNormal = (date: string) => {
  if (!date) return '';
  return date.toString().split('-').reverse().join('-');
};

export const formatDate = (date: Date, format = 'dd-MM-yyyy') => {
  return DateTime.fromISO(date.toISOString()).toFormat(format);
};

export const date = (timestamp: fb.firestore.Timestamp) => {
  return timestamp.toDate().toISOString().slice(0, 10);
};

export function nl2br(payload: string) {
  return payload ? payload.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
}

export function br2nl(payload: string) {
  return payload ? payload.replace(/<br>/g, '\n') : '';
}

export const datapointsAndLabels = (list: any) => {
  let dataObject: any = {
    labels: [],
    data: [],
  };
  list.forEach((item: any) => {
    const [label] = Object.keys(item);
    const [datapoint] = Object.values(item);
    dataObject.labels.push(label);
    dataObject.data.push(datapoint);
  });
  return dataObject;
};

export const threeDatapointsAndLabels = (list: any) => {
  let dataObject: any = {
    labels: [],
    data: [],
  };
  list.priorities.forEach((item: any) => {
    const [label] = Object.keys(item);
    const datapoints: any = Object.values(item);
    const values = [
      datapoints[0].interest,
      datapoints[0].satisfied,
      datapoints[0].interest - datapoints[0].satisfied,
    ];
    dataObject.labels.push(label);
    dataObject.data.push(values);
  });
  return dataObject;
};
