






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AppLink extends Vue {
  @Prop({ required: true })
  public to!: any;
}
