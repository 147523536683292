























































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DialogContact extends Vue {
  public form = {
    name: '',
    company: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };

  public discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    dialog.hide();
    this.$emit('discard');
  }

  public async error() {
    this.$bce.message(this.$t('message-not-sent'), 2);
  }

  public async submit() {
    await this.$firebase.api.mail({ ...this.form });
    this.$bce.message(this.$t('message-sent'), 2);
    this.discard();
  }
}
