





































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class FormuleBoosterLogo extends Vue {
  @Prop({ type: String, default: 'dark' })
  public mode!: string;
}
