import PasswordValidator from 'password-validator';

export class PasswordValidation {
  private readonly _password: string;

  constructor(password: string) {
    this._password = password;
  }

  public validate() {
    return (
      this.validateMin() && this.validateDigit() && this.validateCharacters()
    );
  }

  public validateMin() {
    return new PasswordValidator().is().min(8).validate(this._password);
  }

  public validateDigit() {
    return new PasswordValidator().has().digits().validate(this._password);
  }

  public validateCharacters() {
    return new PasswordValidator()
      .has()
      .symbols()
      .has()
      .not()
      .spaces()
      .validate(this._password);
  }
}
