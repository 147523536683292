






















import { Permission } from '@app/models';
import { Vue, Component, Prop, Watch, Emit, Ref } from 'vue-property-decorator';

import AppMenuItem from './app-menu-item.vue';

interface MenuItem {
  [key: string]: {
    icon: string;
    label: string;
    params?: object;
    admin?: boolean;
    permission?: Permission[];
    children?: MenuItem;
    mobile?: boolean;
    fb?: boolean;
    lv?: boolean;
  };
}

@Component({
  components: {
    AppMenuItem,
  },
})
export default class AppMenu extends Vue {
  @Prop({ type: Boolean })
  public isMobile!: boolean;

  public get admin() {
    return this.$user.admin;
  }

  public get menu(): MenuItem {
    // Default to non-empty string to prevent warnings on refresh.
    const company_id = this.$company.id || '...';

    return !this.$user.online
      ? {
          solutions: {
            icon: 'fas:chart-line',
            label: 'solutions',
            mobile: true,
            lv: true,
            fb: false,
          },
          benefits: {
            icon: 'fas:chart-line',
            label: 'benefits',
            mobile: true,
            lv: true,
            fb: false,
          },
          stories: {
            icon: 'fas:chart-line',
            label: 'stories',
            mobile: true,
            lv: true,
            fb: false,
          },
          method: {
            icon: 'fas:newspaper',
            label: 'method',
            mobile: true,
            lv: false,
            fb: true,
          },
          'dashboard-intro': {
            icon: 'fas:chart-line',
            label: 'dashboard-intro',
            mobile: true,
            lv: false,
            fb: true,
          },
          contact: {
            icon: 'fas:phone',
            label: 'contact',
            mobile: true,
          },
          about: {
            icon: 'fas:user',
            label: 'about',
            mobile: true,
          },
          'sign-in': {
            icon: 'fas:sign-out-alt',
            label: 'sign-in',
            mobile: true,
          },
        }
      : {
          'research-overview': {
            label: 'researches',
            icon: 'fas:home',
            params: { company_id },
            permission: ['research-overview'],
            lv: true,
            fb: true,
          },
          'admin-respondent': {
            label: 'respondents',
            icon: 'fas:users',
            permission: ['respondent'],
            lv: true,
            fb: true,
          },
          organization: {
            label: 'company',
            icon: 'fas:building',
            children: {
              'company-settings': {
                label: 'settings',
                icon: 'fas:cogs',
                params: { company_id },
                permission: ['company-settings'],
                lv: true,
                fb: true,
              },
              'company-users': {
                label: 'users',
                icon: 'fas:user-friends',
                params: { company_id },
                permission: ['company-users'],
                lv: true,
                fb: true,
              },
              'company-roles': {
                label: 'roles',
                icon: 'fas:user-tag',
                params: { company_id },
                permission: ['company-roles'],
                lv: true,
                fb: true,
              },
            },
          },
          management: {
            label: 'management',
            icon: 'fas:table',
            admin: true,
            children: {
              'admin-company-management': {
                label: 'admin-company-management',
                icon: 'fas:city',
                lv: true,
                fb: true,
              },
              'admin-role-management': {
                label: 'admin-role-management',
                icon: 'fas:user-tag',
                lv: true,
                fb: true,
              },
              'admin-global-dashboard': {
                label: 'admin-global-dashboard',
                icon: 'fas:chart-bar',
                lv: true,
                fb: true,
              },
              editor: {
                label: 'admin-module-management',
                icon: 'fas:shapes',
                lv: true,
                fb: true,
              },
              'filter-management': {
                label: 'admin-filter-management',
                icon: 'fas:filter',
                lv: true,
                fb: true,
              },
            },
          },
          support: {
            label: 'support',
            icon: 'fas:info',
            children: {
              contact: {
                label: 'contact',
                icon: 'fas:comment-alt',
                lv: true,
                fb: true,
              },
              instructions: {
                label: 'instructions',
                icon: 'fas:chalkboard-teacher',
                lv: true,
                fb: true,
              },
              faq: {
                label: 'faq',
                icon: 'fas:question-circle',
                lv: true,
                fb: true,
              },
              'terms-and-conditions': {
                label: 'terms-and-conditions',
                icon: 'fas:file-contract',
                lv: true,
                fb: true,
              },
              privacy: {
                label: 'privacy',
                icon: 'fas:user-secret',
                lv: true,
                fb: true,
              },
            },
          },
          account: {
            label: 'account',
            icon: 'fas:cog',
            lv: true,
            fb: true,
          },
          'sign-out': {
            label: 'sign-out',
            icon: 'fas:sign-out-alt',
            lv: true,
            fb: true,
          },
        };
  }

  public showChildren: string = '';

  public toggleChildren(label: string) {
    this.showChildren === label
      ? (this.showChildren = '')
      : (this.showChildren = label);
  }
}
