import Vue from 'vue';
import Vuex from 'vuex';

import { vuexfireMutations } from 'vuexfire';

Vue.use(Vuex);

export const store = new Vuex.Store({
  mutations: vuexfireMutations,
});
