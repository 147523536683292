
















































import { User } from '@app/models';
import Vue from 'vue';
import { Component, Watch, ProvideReactive } from 'vue-property-decorator';

import { UiModule } from './store/modules/ui-module';
import { getModule } from 'vuex-module-decorators';

import FormuleBoosterLoader from './components/0-formule-booster/formule-booster-loader.vue';
import AppFooter from './components/1-navigation/app-footer.vue';
import AppHeader from './components/1-navigation/app-header.vue';
import AppMenu from './components/1-navigation/app-menu.vue';
import DialogContact from './components/3-dialogs/dialog-contact.vue';
import FilterSideBar from './components/6-other/filter-side-bar.vue';

@Component({
  components: {
    AppFooter,
    AppHeader,
    AppMenu,
    DialogContact,
    FilterSideBar,
    FormuleBoosterLoader,
  },
})
export default class App extends Vue {
  @ProvideReactive() isMobile: boolean = false;
  private ui = getModule(UiModule);

  public contact = false;
  public loader = { title: '', message: '' };

  public get forcedSidebar() {
    const route = this.$route.name || '';
    const routes = ['index', 'complete-research', 'respondent'];
    return !this.$user.complete || routes.indexOf(route) >= 0;
  }

  public get sidebar() {
    const route = this.$route.name || '';
    const routes = ['index', 'complete-research', 'respondent'];
    return this.forcedSidebar ? 'closed' : this.ui.menu;
  }

  public mounted() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
    // window.addEventListener('beforeunload', this.beforeUnload);

    this.$root.$on('app:loader', this.onLoader);
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
    // window.removeEventListener('beforeunload', this.beforeUnload);
  }

  public destroyed() {
    this.$root.$off('app:loader', this.onLoader);
  }

  public beforeUnload(event: BeforeUnloadEvent) {
    // Note most browsers will just show a default message and ignore the
    // provided message, passing a message is still required to trigger the
    // default message.
    const message =
      this.$user && this.$user.online && !this.$user.complete
        ? 'Changes you made may not be saved.'
        : false;

    if (message) event.returnValue = message;
    return message;
  }

  @Watch('$user.data', { deep: true, immediate: false })
  public async watchUser() {
    await this.$user.ready;

    // Pull for token changes, max 10 times, with i seconds in between attemps
    for (let i = 1; i <= 10; i++) {
      if (!this.requireTokenRefresh()) break;
      await this.$user.refreshToken();
      await new Promise(res => setTimeout(res, 1000 * i));
    }
  }

  @Watch('$company.color')
  public watchCompanyColor() {
    const root = this.$root.$el as HTMLBceRootElement;
    root.accent('primary', this.$company.color);
  }

  @Watch('$company.color2')
  public watchCompanyColor2() {
    const root = this.$root.$el as HTMLBceRootElement;
    root.accent('secondary', this.$company.color2);
  }

  @Watch('$company.id')
  public async watchCompanyId() {
    if (this.$company.id || !this.$company.list.length) return;

    const [first] = this.$company.list;
    await this.$company.select(first.id);
  }

  public getWidth() {
    window.innerWidth <= 1024
      ? (this.isMobile = true)
      : (this.isMobile = false);
  }

  private requireTokenRefresh(): boolean {
    if (!this.$user.data) return false;

    // Retrieve token & database data
    const { admin, complete, company } = this.$user;
    const { status } = this.$user.data;

    // Check company
    if (company.length !== this.$user.data.company.length) return true;
    for (let i = 0; i < company.length; i++)
      if (company[i] !== this.$user.data.company[i]) return true;

    // Check status
    return !!status.admin !== !!admin || !!status.complete !== !!complete;
  }

  private onLoader(title = '', message = '') {
    this.loader = { title, message };
  }

  public onContact(data: any) {
    this.contact = false;
    this.$firebase.api.mail(data);
    this.$bce.message(this.$t('message-sent'), 2);
  }

  public onWhatsapp() {
    const { phone } = webpack.CONFIG.admin;
    window.open(`https://api.whatsapp.com/send?phone=${phone}`);
  }
}
