import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { PluginFunction } from 'vue';

const install: PluginFunction<{}> = () => {
  library.add(fas, far, faWhatsapp);
};

export default { install };
