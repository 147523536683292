export const threeDataSetsAndLabels = (list: any) => {
  let dataObject: any = {
    labels: [],
    datasets: [
      {
        label: ['Belang'],
        backgroundColor: '#a7414a',
        data: [],
        bevelWidth: 2,
        bevelHighlightColor: 'rgba(255, 255, 255, 0.15)',
        bevelShadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffsetX: 24,
        shadowOffsetY: 12,
        shadowBlur: 7,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        minBarLength: 2,
      },
      {
        label: ['Tevredenheid'],
        backgroundColor: '#282726',
        data: [],
        bevelWidth: 2,
        bevelHighlightColor: 'rgba(255, 255, 255, 0.15)',
        bevelShadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffsetX: 24,
        shadowOffsetY: 12,
        shadowBlur: 7,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        minBarLength: 2,
      },
      {
        label: ['Belang -/- Tevredenheid'],
        backgroundColor: '#6a8a82',
        data: [],
        bevelWidth: 2,
        bevelHighlightColor: 'rgba(255, 255, 255, 0.15)',
        bevelShadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffsetX: 24,
        shadowOffsetY: 12,
        shadowBlur: 7,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        minBarLength: 2,
      },
    ],
  };

  if (!list) return dataObject;

  const [interestTotal, satisfiedTotal, interestMinusSatisfiedTotal] =
    dataObject.datasets;

  list.forEach((item: any) => {
    const [label] = item[0];
    const [interestValue, satisfiedValue, interestMinusSatisfiedValue] =
      item[1];

    dataObject.labels.push(label);
    interestTotal.data.push(interestValue);
    satisfiedTotal.data.push(satisfiedValue);
    interestMinusSatisfiedTotal.data.push(interestMinusSatisfiedValue);
  });
  return dataObject;
};

export const getPercentage = (percentOf: number, percentFor: number) => {
  return Math.floor((percentFor / percentOf) * 100) === Infinity
    ? 0
    : Math.floor((percentFor / percentOf) * 100);
};

export const sortDataAndLabels = (data: any) => {
  const keyvaluepair = data.dataset.map((val: any, i: number) => {
    const label = data.labels[i];
    return [label, val];
  });
  keyvaluepair.sort((a: any, b: any) => {
    return b[1] - a[1];
  });

  return {
    labels: keyvaluepair.map((item: any) => item[0]),
    dataset: keyvaluepair.map((item: any) => item[1]),
  };
};

export const getTopThree = (data: any) => {
  return {
    labels: data.labels.slice(0, 3),
    dataset: data.dataset.slice(0, 3),
  };
};

export const getPostitive = (data: any) => {
  const split = Math.ceil(data.length / 2);

  const total = data.reduce((acc: number, currValue: number, index: number) => {
    const positive = index + 1 > split;
    if (positive) return acc + currValue;
    return acc;
  }, 0);

  return total;
};
