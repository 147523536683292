import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { CompanyAuth } from '@app/models';
import fb from 'firebase/app';
import { firestoreAction } from 'vuexfire';
import { Action } from 'vuex';

const config = webpack.CONFIG.firebase;
const app = fb.initializeApp(config);

type Reference =
  | fb.firestore.CollectionReference
  | fb.firestore.DocumentReference
  | fb.firestore.Query;

class Firebase {
  private readonly _auth = app.auth();
  private readonly _firestore = app.firestore();
  private readonly _storage = app.storage();

  public readonly account = {
    complete: () => {
      return this.request<string>('account/complete', {});
    },
    delete: async (uid: string) => {
      // No request, remaining data is deleted by database watchers.
      await this.doc(`user/${uid}`).delete();
    },
    license: async (id: string, license: string, message: string) => {
      await this.request('account/license', { id, license, message });
    },
    password: async (password: string) => {
      await this.request('account/password', { password });
    },
    quickStart: (company: string, email: string, research: string) => {
      const data = { company, email, research };
      return this.request<string>('account/quick-start', data);
    },
    start: (email: string, research: string) => {
      return this.request<string>('account/start', { email, research });
    },
  };

  public readonly admin = {
    license: async (id: string, company: string, date: string | false) => {
      await this.request(
        'admin/license',
        date ? { id, company, date } : { id, company }
      );
    },
    topic: async (topic: string, data?: any) => {
      await this.request('admin/topic', { topic, data });
    },
  };

  public readonly api = {
    mail: async (emailOptions: any) => {
      await this.request('mail', emailOptions);
    },
    token: (code: string) => {
      return this.request<string>('token', { code });
    },
  };

  public readonly company = {
    invitation: (id: string) => {
      return this.request<string>('company/invitation', { id });
    },
    invite: async (
      cid: string,
      email: string,
      data: Partial<CompanyAuth> = {}
    ) => {
      await this.request('company/invite', { company_id: cid, email, data });
    },
  };

  public get onAuth() {
    return this._auth.onAuthStateChanged.bind(this._auth);
  }

  public get col() {
    return this._firestore.collection.bind(this._firestore);
  }

  public get colGroup() {
    return this._firestore.collectionGroup.bind(this._firestore);
  }

  public get doc() {
    return this._firestore.doc.bind(this._firestore);
  }

  public get batch() {
    return this._firestore.batch.bind(this._firestore);
  }

  public get runTransaction() {
    return this._firestore.runTransaction.bind(this._firestore);
  }

  public get file() {
    return this._storage.ref.bind(this._storage);
  }

  public get FieldValue() {
    return fb.firestore.FieldValue;
  }

  public signIn(token: string) {
    return this._auth.signInWithCustomToken(token);
  }

  public signOut() {
    return this._auth.signOut();
  }

  public generateId(): string {
    return this._firestore.collection('<generate_id>').doc().id;
  }

  public fromDate(timestamp: Date) {
    return fb.firestore.Timestamp.fromDate(timestamp);
  }

  public async refreshToken() {
    return (
      this._auth.currentUser &&
      (await this._auth.currentUser.getIdTokenResult(true))
    );
  }

  public toData<T>(snap: fb.firestore.DocumentSnapshot): T | undefined;
  public toData<T>(snap: fb.firestore.QuerySnapshot): T[];
  public toData(
    snap: fb.firestore.DocumentSnapshot | fb.firestore.QuerySnapshot
  ) {
    // prettier-ignore
    return 'data' in snap
      ? snap.exists ? snap.data() : undefined
      : snap.empty  ? [] : snap.docs.map(d => d.data());
  }

  public async bind(module: any, key: string, ref: Reference) {
    const action = firestoreAction(c => c.bindFirestoreRef(key, ref as any));
    return this.vuexFireAction(module, action);
  }

  public async unbind(module: any, key: string) {
    const action = firestoreAction(c => c.unbindFirestoreRef(key));
    await this.vuexFireAction(module, action);
  }

  private vuexFireAction(module: any, action: Action<unknown, unknown>) {
    if (typeof action !== 'function') throw new Error('Unexpected action');
    return (action as Function)(module.context, undefined) as Promise<void>;
  }

  private async request<T>(path: string, data: any): Promise<T> {
    // Construct headers
    const headers = new Map<string, string>();
    headers.set('Content-Type', 'application/json');

    // Add authorization token to headers
    if (this._auth.currentUser) {
      const token = await this._auth.currentUser.getIdToken();
      headers.set('Authorization', 'Bearer ' + token);
    }

    // Perform request
    const response = await fetch(`${config.apiPath}/${path}`, {
      headers: [...headers].reduce(
        (acc, cur) => (acc[cur[0]] = cur[1]) && acc,
        {} as any
      ),
      method: 'POST',
      body: data ? JSON.stringify(data) : undefined,
    });

    const result = await response.json();
    if (result.error) throw new Error(result.code);
    return result.data;
  }
}

export const firebase = new Firebase();
(window as any).firebase = firebase;
