















































































import 'vue-slider-component/theme/default.css';

import { Filter, Result } from '@app/models';
import { debounce } from '@bcase/core';
import { BlockElement } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import { getModule } from 'vuex-module-decorators';

import { StoredFilter } from '../../models/stored-filter';
import { ResultModule } from '../../store/modules/result-module';

@Component({
  components: { VueSlider },
})
export default class FilterSideBarItem extends Vue {
  @Prop()
  public check?: (filter: Filter) => StoredFilter['check'];

  @Prop()
  public filter!: Filter;

  @Prop()
  public icon?: string;

  @Prop()
  public multi?: boolean;

  @Ref('matrix')
  private matrix?: HTMLBceElementMatrixElement;

  public debounceSliderChange = debounce(this.sliderChange, 500);
  public open = false;

  private result = getModule(ResultModule);

  public get active() {
    return !!this.result.filters.find(f => f.id === this.filter.id);
  }

  public get matrixTemplate() {
    if (this.filter.element.type !== 'matrix') return {};

    return {
      columns: this.filter.element.value.columns,
      rows: this.filter.element.value.rows.map((_: any, i: number) => ({
        text: '' + (i + 1),
      })),
    };
  }

  public get matrixValue() {
    if (this.filter.element.type !== 'matrix') return [];

    const base = this.filter.element.value.rows.map(() => null);
    const filter = this.result.filters.find(f => f.id === this.filter.id);
    if (!filter) return base;

    for (const option of filter.options) {
      const [rowStr, colStr] = (option.value as string).split(',');
      const row = parseInt(rowStr, 10) - 1;
      const col = parseInt(colStr, 10);
      base[row] = col;
    }

    return base;
  }

  public get multiselect() {
    if (this.multi != undefined) return this.multi;

    switch (this.filter.element.type) {
      case 'checkbox':
      case 'matrix':
        return true;
      default:
        return false;
    }
  }

  public get collapsible() {
    return `${this.filter.id}-collapsible`;
  }

  @Watch('matrixValue')
  public watchMatrixValue() {
    if (this.matrix) this.matrix.value = this.matrixValue;
  }

  public add(index: number | number[], name?: string) {
    const cur = this.result.filters.find(f => f.id === this.filter.id);

    const option: StoredFilter['options'][number] = {
      active: true,
      name: Array.isArray(index) ? index.join(' - ') : name || '',
      value: Array.isArray(index) ? index.join(',') : index,
    };

    const options =
      this.multiselect && cur
        ? [...cur.options, option].sort((a: any, b: any) => a.value - b.value)
        : [option];

    this.result.filterAdd({
      category: this.filter.label,
      check: this.check
        ? this.check(this.filter)
        : this.result.checkCustom(this.filter).bind(this.result),
      id: this.filter.id,
      options,
    });
  }

  public getIcon(type: BlockElement.Type) {
    return BlockElement.icon(type);
  }

  public getValue(index: number) {
    if (this.filter.element.type === 'slider') {
      const filter = this.result.filters.find(f => f.id === this.filter.id);
      if (filter) return (filter.options[0].value as string).split(',');

      const { min, max } = this.filter.element.value;
      return [min, max];
    }

    return !!this.result.filters.find(f => {
      return f.id === this.filter.id && f.options.find(o => o.value === index);
    });
  }

  public matrixChange(value: (number | null)[]) {
    for (let row = 0; row < value.length; row++) {
      // Reset row filter
      for (let col = 0; col < this.filter.element.value.columns.length; col++)
        this.remove([row + 1, col]);

      // Add new filter
      const column = value[row];
      if (column != null) this.add([row + 1, column]);
    }
  }

  public remove(index?: number | number[]) {
    this.result.filterRemove({
      id: this.filter.id,
      index: Array.isArray(index) ? index.join(',') : index,
    });
  }

  public sliderChange(value: number[]) {
    const { min, max } = this.filter.element.value;
    if (value[0] === min && value[1] === max) this.remove();
    else this.add(value);
  }
}
