import Vue, { PluginFunction } from 'vue';
import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { CmsModule, i18n } from '../store/modules/cms-module';

const cms = getModule(CmsModule);

@Component
export class I18NMixin extends Vue {
  public get $i18n() {
    return i18n;
  }

  public get $t() {
    return cms.t.bind(cms);
  }

  created() {
    cms.bind('nl');
  }
}

const install: PluginFunction<{}> = async _Vue => {
  _Vue.mixin(I18NMixin);
};

export default { install };
