import {
  Dashboard,
  DashboardResult,
  DashboardResultMatrix,
  DashboardResultOther,
} from '@app/models';
import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';

import {
  threeDataSetsAndLabels,
  sortDataAndLabels,
  getTopThree,
  getPostitive,
  getPercentage,
} from '../../utils/data-manipulation';
import { firebase } from '../../utils/firebase';
import { store } from '../store';
import { user } from './user-module';

@Module({ dynamic: true, store, name: 'dashboard', namespaced: true })
export class DashboardModule extends VuexModule {
  private _initialized: boolean = false;
  private _dashboard: Dashboard[] = [];
  private _setResearchId: string = '';

  public get initialized() {
    return this._initialized;
  }

  public get active() {
    return this._dashboard.find(dashboard => dashboard.active);
  }

  public get find() {
    return (id: string) =>
      this._dashboard.find(dashboard => dashboard.id === id);
  }

  public get findResult() {
    return (id: string) => this.dashboard && this.dashboard.allResults[id];
  }

  public get researchId() {
    return this._setResearchId;
  }

  public get dashboard() {
    return this._dashboard && this._dashboard[0];
  }

  public get dashboardsPendingById() {
    return (id: string) =>
      this._dashboard.find((dashboard: Dashboard) => dashboard.id == id);
  }

  public get pendingResults() {
    // TODO: incorrect sorting
    return (
      this._dashboard[0] &&
      Object.values(this._dashboard[0].allResults).sort(
        (DashboardResultA: any, DashboardResultB: any) =>
          DashboardResultA.nr - DashboardResultB.nr
      )
    );
  }

  public get dashboardItems() {
    return (
      this._dashboard[0] &&
      Object.values(this._dashboard[0].allResults).filter(
        (DashboardResult: DashboardResult) => DashboardResult.dashboard
      )
    );
  }

  public get filters() {
    return this.dashboard && this.dashboard.filters;
  }

  public get moduleNames() {
    if (!this.pendingResults) return [''];

    // Return unique module names
    return [];

    // this.pendingResults
    //   .map(res => res.moduleName)
    //   .filter((v, i, s) => s.indexOf(v) === i);
  }

  public get dashboardItem(): GetItem {
    return (name: string) =>
      this.dashboardItems &&
      (this.dashboardItems.find(
        DashboardResult =>
          DashboardResult.dashboard === name &&
          DashboardResult.target === 'entrepreneurs'
      ) as any);
  }

  public get gradeDashboard() {
    const gradeData = this.dashboardItem('grade');
    if (!gradeData) return 0;
    let totalRespondents = 0;

    const reducer = (acc: number, currValue: number, index: number) => {
      const grade = parseInt(gradeData.labels[index], 10);
      const total = grade * currValue;
      totalRespondents += currValue;
      return acc + total;
    };

    const totalGrade = gradeData.dataset.reduce(reducer, 0);
    return totalGrade / totalRespondents;
  }

  public get continuityDashboard() {
    const continuityData = this.dashboardItem('continuity');

    if (!continuityData) return 0;
    let totalRespondents = 0;
    const split = continuityData.dataset.length / 2;
    const reducer = (acc: number, currValue: number, index: number) => {
      const positive = index + 1 > split;
      totalRespondents += currValue;
      if (positive) return acc + currValue;
      return acc;
    };
    const total = continuityData.dataset.reduce(reducer, 0);
    return getPercentage(totalRespondents, total);
  }

  public get strategicValuesDashboard() {
    const strategicValuesData = this.dashboardItem('strategic-values');
    if (!strategicValuesData) return { labels: [], dataset: [] };
    return getTopThree(sortDataAndLabels(strategicValuesData));
  }

  public get missingValuesDashboard() {
    const missingvalueData = this.dashboardItem('missing-values');
    if (!missingvalueData) return { labels: [], dataset: [] };
    return getTopThree(sortDataAndLabels(missingvalueData));
  }

  public get participationDashboard() {
    const participationData = this.dashboardItem('participation');
    if (!participationData) return { labels: [], dataset: [] };
    return getTopThree(sortDataAndLabels(participationData));
  }

  public get prioritiesDashboard() {
    const subjectImportanceData = this.dashboardItem('subject-importance');
    const subjectSatisfactionData = this.dashboardItem('subject-satisfaction');

    if (!subjectImportanceData || !subjectSatisfactionData) {
      return threeDataSetsAndLabels([]);
    }
    const label = subjectImportanceData.labels;

    let data = [];
    for (let key in subjectImportanceData.dataset) {
      const importance = getPostitive(subjectImportanceData.dataset[key]);
      const satisfaction = getPostitive(subjectSatisfactionData.dataset[key]);
      data.push([
        [label[key]],
        [importance, satisfaction, importance - satisfaction],
      ]);
    }
    data.sort((a: any, b: any) => {
      return b[1][2] - a[1][2];
    });
    return threeDataSetsAndLabels(data);
  }

  @Mutation
  public initialize(payload: boolean) {
    this._initialized = payload;
  }

  @Mutation
  public setResearchId(payload: string) {
    this._setResearchId = payload;
  }

  @Action({ rawError: true })
  public async bind(payload: { id: string; status: string }) {
    const status = payload.status === 'complete';
    const collection = status ? 'dashboard' : 'dashboard-pending';

    if (!payload.id) {
      await user.ready;
      const { id } = user;

      if (id) {
        const refDashboard = firebase
          .col(collection)
          .where('company', 'in', user.company);

        await firebase.bind(this, '_dashboard', refDashboard);
        this.initialize(true);
      }
      return;
    }

    if (this.researchId === payload.id) return;

    const refDashboard = user.admin
      ? firebase.col(collection).where('id', '==', payload.id)
      : firebase
          .col(collection)
          .where('company', 'in', user.company)
          .where('id', '==', payload.id);

    await firebase.bind(this, '_dashboard', refDashboard);

    this.setResearchId(payload.id);
    this.initialize(true);
  }

  @Action({ rawError: true })
  public async unbind() {
    await firebase.unbind(this, '_dashboard');
    // await firebase.unbind(this, '_dashboards');
    this.initialize(false);
    this.setResearchId('');
  }
}

interface GetItem {
  (type: 'subject-importance' | 'subject-satisfaction'):
    | DashboardResultMatrix
    | undefined;
  (
    type:
      | 'grade'
      | 'slider'
      | 'input'
      | 'continuity'
      | 'strategic-values'
      | 'missing-values'
      | 'participation'
      | 'core-value-1'
      | 'core-value-2'
      | 'products-services-1'
      | 'products-services-2'
  ): DashboardResultOther | undefined;
}
