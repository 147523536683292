import { FileReference } from '@app/models';
import { BceFile, FileManager } from '@bcase/core';
import fb from 'firebase/app';
import Vue from 'vue';
import {
  Action,
  Module,
  VuexModule,
  Mutation,
  getModule,
} from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { store } from '../store';

interface Upload {
  name: string;
  progress: string;
  error?: fb.FirebaseError;
  complete: boolean;
}

interface LocationMap {
  [id: string]: string;
}

let unsubscribe: Function = () => {};

// The task map is stored out of the Vuex state because it can not be
// serialized. In this case it doesn't matter because the upload state is a
// local state which is never serialized.
const TASKS = new Map<string, fb.storage.UploadTask>();

@Module({ dynamic: true, store, name: 'storage', namespaced: true })
export class StorageModule extends VuexModule {
  private _files: FileReference[] = [];
  private _locations: LocationMap = {};
  private _uploads: { [id: string]: Upload } = {};
  private _group = '';

  public get uploads() {
    return this._uploads;
  }

  public get selected() {
    return this._group;
  }

  public get locations() {
    return this._locations;
  }

  public get file() {
    return (path: string) => {
      const match = path.match(/BCE:\/\/([^/]+)\//);
      const id = match ? match[1] : path;
      return this._locations[id];
    };
  }

  @Action({ rawError: true })
  public async bind(group: string) {
    if (this.selected && this.selected === group) return;

    // Bind files
    const ref = firebase.col('file').where('group', 'array-contains', group);
    await firebase.bind(this, '_files', ref);
    this.SELECT_GROUP(group);

    // Load URLs on every change
    unsubscribe();
    unsubscribe = ref.onSnapshot(async () => {
      const tasks = this._files.map(async file => {
        const url: string = await firebase.file(file.ref).getDownloadURL();
        return { id: file.id, url };
      });

      const locationArr = await Promise.all(tasks);
      const locationMap = locationArr.reduce<LocationMap>((acc, val) => {
        acc[val.id] = val.url;
        return acc;
      }, {});

      this.LOAD_LOCATIONS(locationMap);
    });
  }

  @Action({ rawError: true })
  public async unbind() {
    this.SELECT_GROUP('');
    this.LOAD_LOCATIONS({});
  }

  @Action({ rawError: true })
  public async upload(payload: { ref: string; group: string; file: BceFile }) {
    const { group, file } = payload;
    const { id, name, blob } = file;

    const ref = payload.ref
      .replace(/{id}/g, id)
      .replace(/{ext}/g, name.split('.').pop() || '');
    const metadata = { contentDisposition: `attachment; filename="${name}"` };
    const task = firebase.file(ref).put(blob, metadata);

    this.UPLOAD({ id, name, task });

    return new Promise<FileReference>((res, rej) => {
      task.on(
        'state_changed',
        snapshot => {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes;
          this.UPLOAD_PROGRESS({ id, progress });
          fileManager.setProgress(id, progress);
        },
        e => {
          const error = e as fb.FirebaseError;
          if (error.code === 'storage/cancelled') {
            this.UPLOAD_STATUS({ id, error });
            setTimeout(() => this.UPLOAD_REMOVE(id), 5000);
            return rej(error);
          }

          this.UPLOAD_STATUS({ id, error });
          rej(error);
        },
        async () => {
          this.UPLOAD_STATUS({ id, complete: true });
          setTimeout(() => this.UPLOAD_REMOVE(id), 2500);
          const url = await task.snapshot.ref.getDownloadURL();

          const entry: FileReference = {
            group: [group],
            hash: await file.hash(),
            id,
            name: file.name,
            ref,
            size: file.blob.size,
            type: file.type,
            url,
          };
          await firebase.doc('file/' + id).set(entry);
          res(entry);
        }
      );
    });
  }

  @Action({ rawError: true })
  public async delete(payload: { id: string; group: string }) {
    const cancelled = await this.cancel(payload.id);
    if (cancelled) return true;

    const ref = firebase.doc(`file/${payload.id}`);
    return firebase.runTransaction(async transaction => {
      const file = await transaction
        .get(ref)
        .then(snap => snap.data() as FileReference);

      // Remove provided group from file
      const group = file.group.filter(g => g !== payload.group);

      // There's still another reference to this file, keep it and just update
      // the database.
      if (group.length) {
        transaction.update(ref, { group });
        return true;
      }

      // There's no more reference to this file, delete it from the database and
      // the storage.
      await firebase.file(file.ref).delete();
      transaction.delete(ref);

      return true;
    });
  }

  @Action({ rawError: true })
  public async cancel(payload: string) {
    const task = TASKS.get(payload);
    return task ? task.cancel() : false;
  }

  @Action({ rawError: true })
  public async rename(payload: { id: string; name: string }) {
    const update: Partial<FileReference> = { name: payload.name };
    return firebase.doc(`file/${payload.id}`).update(update);
  }

  @Mutation
  private SELECT_GROUP(group: string) {
    this._group = group;
  }

  @Mutation
  private LOAD_LOCATIONS(locations: LocationMap) {
    this._locations = locations;
  }

  @Mutation
  private UPLOAD(payload: {
    id: string;
    name: string;
    task: fb.storage.UploadTask;
  }) {
    const { id, name, task } = payload;
    const upload: Upload = {
      name,
      progress: '',
      error: undefined,
      complete: false,
    };

    Vue.set(this._uploads, id, upload);
    TASKS.set(id, task);
  }

  @Mutation
  private UPLOAD_PROGRESS(payload: { id: string; progress: number }) {
    const { id, progress } = payload;
    const upload: Upload = {
      ...this._uploads[id],
      progress: (progress * 100).toFixed(0),
    };

    Vue.set(this._uploads, id, upload);
  }

  @Mutation
  private UPLOAD_STATUS(payload: {
    id: string;
    error?: fb.FirebaseError;
    complete?: boolean;
  }) {
    const { id, ...rest } = payload;
    const upload: Upload = {
      ...this._uploads[id],
      ...rest,
    };

    Vue.set(this._uploads, id, upload);
  }

  @Mutation
  private UPLOAD_REMOVE(id: string) {
    Vue.delete(this._uploads, id);
    TASKS.delete(id);
  }
}

const storage = getModule(StorageModule);

export const fileManager = new FileManager({
  cancel: async id => {
    await storage.cancel(id);
  },
  delete: async (id, metadata) => {
    await storage.delete({ ...metadata, id });
  },
  rename: async (id, name) => {
    await storage.rename({ id, name });
  },
  upload: (file, metadata) => storage.upload({ ...metadata, file }),
});

let unsubscribeOnAuth: () => void | undefined;
firebase.onAuth(u => {
  if (unsubscribeOnAuth) unsubscribeOnAuth();
  if (u) {
    unsubscribeOnAuth = firebase.col('file').onSnapshot(snap => {
      const refs = firebase.toData<FileReference>(snap);
      fileManager.setFiles(refs);
    });
  } else fileManager.setFiles([]);
});
