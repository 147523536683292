import { Result, DashboardResult } from '@app/models';

export const combinedDashboardUpdateUtils = (results: any) => {
  const hashTable = {} as any;
  for (const result in results) {
    const item = results[result] as Result[];
    const dashboardResult = initDashboardResult(item[0]);

    // prettier-ignore
    const temp = item.reduce((acc, element) => {
      return addDashboardResult( acc,element);
    }, dashboardResult);
    hashTable[result] = temp;
  }
  return hashTable;
};

export const parseResult = (
  result: Result,
  dashboard?: DashboardResult
): DashboardResult => {
  const base = dashboard || initDashboardResult(result);
  return addDashboardResult(base, result);
};

const addDashboardResult = (
  dashboard: DashboardResult,
  result: Result
): DashboardResult => {
  const responses = dashboard.responses + 1;
  const dataset = addDashboardResultDataset(dashboard, result) as any;
  const order = dashboard.order || result.order;

  const next = { ...dashboard, dataset, order, responses };

  if (
    result.answer.other &&
    (result.question.type === 'radio' || result.question.type === 'checkbox')
  ) {
    const { value } = result.answer;
    const otherIndex = result.question.options.length - 1;
    const other = Array.isArray(value)
      ? value.find((v: any) => v.index === otherIndex)
      : value.index === otherIndex && value;
    if (other) next.others = [...next.others, other.text];
  }

  return next;
};

const addDashboardResultDataset = (
  dashboard: DashboardResult,
  result: Result
): DashboardResult['dataset'] => {
  switch (dashboard.type) {
    case 'checkbox': {
      const value: { index: number }[] = result.answer.value;
      return dashboard.dataset.map((current, index) => {
        return value.find(v => v.index === index) ? current + 1 : current;
      });
    }
    case 'matrix': {
      const value: number[] = result.answer.value;
      return Object.keys(dashboard.dataset).reduce((acc, key) => {
        const index = parseInt(key, 10);
        const choice = value[index];
        if (choice != null) acc[index][choice] += 1;
        return acc;
      }, dashboard.dataset);
    }
    case 'icon': {
      const index: number = result.answer.value;
      return dashboard.dataset.map((val, i) => (i === index ? val + 1 : val));
    }
    case 'input': {
      return [...dashboard.dataset, result.answer.value];
    }
    case 'slider': {
      if (result.question.type !== 'slider') return dashboard.dataset;
      const index: number = result.answer.value - result.question.min;
      return dashboard.dataset.map((val, i) => (i === index ? val + 1 : val));
    }
    default: {
      const index: number = result.answer.value.index;
      return dashboard.dataset.map((val, i) => (i === index ? val + 1 : val));
    }
  }
};

const initDashboardResult = (result: Result): DashboardResult => {
  const { dataset, labels, labelsDataset } = initDataset(result);
  return {
    dashboard: result.dashboard,
    dataset: dataset as any,
    elementId: result.elementId,
    labels: labels as any,
    labelsDataset: labelsDataset as any,
    nr: result.nr,
    order: result.order,
    others: [],
    responses: 0,
    target: result.target,
    type: result.question.type,
    question: result.question.questionText,
    Xaxis: result.Xaxis,
    Yaxis: result.Yaxis,
  };
};

const initDataset = (result: Result) => {
  const { question } = result;

  switch (question.type) {
    case 'checkbox':
    case 'radio': {
      const labels = question.options.map(value => value.text);
      const dataset = Array(labels.length).fill(0);
      return { labels, dataset };
    }
    case 'icon': {
      const labels = question.options.map(value => value);
      const dataset = Array(labels.length).fill(0);
      return { labels, dataset: dataset };
    }
    case 'input':
      return { labels: 'other', dataset: [] };
    case 'matrix': {
      const labels = question.rows.map(value => value.text);
      const labelsDataset = question.columns.map(value => value.text);
      const dataset = question.rows.reduce((acc, _, i) => {
        acc[`${i}`] = Array(labelsDataset.length).fill(0);
        return acc;
      }, {} as { [index: string]: number[] });
      return { labels, labelsDataset, dataset };
    }
    case 'slider': {
      const start = parseInt('' + (question.min || 0), 10);
      const end = parseInt('' + question.max, 10) + 1;
      const labels = [...Array(end - start).keys()].map(k => k + start);
      const dataset = Array(labels.length).fill(0);
      return { labels, dataset };
    }
    default:
      return { labels: [], dataset: {} };
  }
};
