








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class AppFooter extends Vue {
  public get version() {
    return `v${webpack.VERSION}-${webpack.BUILD_DATE}`;
  }
}
