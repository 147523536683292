import { nl } from '@app/i18n';
import { nl as meNl } from '@bcase/module-editor';
import { I18n } from '@bce-technology/i18n';
import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { router } from '../../utils/router';
import { store } from '../store';

@Module({ dynamic: true, store, name: 'cms', namespaced: true })
export class CmsModule extends VuexModule {
  private _data: I18n.Document | null = null;
  private _language = '';

  private get language() {
    return this._language;
  }

  public get data() {
    if (!this._data) return {} as I18n.Document;

    const { id, ...rest } = this._data;
    return rest;
  }

  public get entries() {
    const entries = this.data;

    return Object.keys(entries).reduce((acc, key) => {
      const entry = entries[key]!;
      return { ...acc, ...entry };
    }, {});
  }

  public get t() {
    i18n.load(this.language as any, this.entries);
    return (key: any, args?: I18n.TranslatorArgs) => {
      const label = i18n.t(key, args);
      return label === '' ? `{${key}}` : label;
    };
  }

  @Action({ rawError: true })
  public async bind(payload: string) {
    if (this.language === payload) return;

    const ref = firebase.doc(`@service/i18n/i18n-language/${payload}`);
    await firebase.bind(this, '_data', ref);
    this.LANGUAGE(payload);
  }

  @Action({ rawError: true })
  public async unbind() {
    if (!this._language) return;

    await firebase.unbind(this, '_data');
    this.LANGUAGE('');
  }

  @Mutation
  public LANGUAGE(language: string) {
    this._language = language;
  }
}

export const i18n = new I18n<string>({
  createArgRegExp,
  language: { nl: { ...meNl, ...nl } },
  locale: 'nl',
  logger: async (key, args, base) => {
    const data = logMissing(key, args, base);
    await firebase.doc(`@service/i18n/i18n-logger/${data.id}`).set(data);
  },
  warning: true,
});

function createArgRegExp(arg?: string) {
  return !arg
    ? /{[^}]*}|\[[^\]]*\]/g
    : new RegExp(`{${arg}}|\\[${arg}\\]`, 'g');
}

function logMissing(
  key: string | boolean,
  args?: I18n.TranslatorArgs,
  base?: string
) {
  return !base
    ? logMissingKey(key, args || {})
    : logMissingArgs(key, args || {}, base);
}

function logMissingKey(key: string | boolean, args?: I18n.TranslatorArgs) {
  const { fullPath: path, name: route } = router.currentRoute;
  const id = `${route}:${key}`;
  return { args, id, key, path, route };
}

function logMissingArgs(
  key: string | boolean,
  args: I18n.TranslatorArgs,
  base: string
) {
  const regex = createArgRegExp();
  const missingArgs = (base.match(regex) || [])
    .map(i => i.slice(1, -1))
    .filter(key => !(key in args))
    .join(',');

  const partial = logMissingKey(key, args);
  const id = `${partial.id}:${missingArgs}`;
  return { ...partial, base, id };
}
