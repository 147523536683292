import { Research, Respondent } from '@app/models';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { router } from '../../utils/router';
import { store } from '../store';
import { user } from './user-module';
import _ from 'lodash';

@Module({ dynamic: true, store, name: 'research', namespaced: true })
export class ResearchModule extends VuexModule {
  private _user: string = '';
  private _data: Research[] = [];
  private _respondents: Respondent[] = [];
  private _demo: Research[] = [];
  private _demoRespondents: Respondent[] = [];

  public get data() {
    return [...this._demo, ...this._data];
  }

  public get current() {
    return this.currentResearch;
  }

  public get dashboardLayout() {
    return this.current && this.current.dashboardLayout;
  }

  public get respondents() {
    return [...this._demoRespondents, ...this._respondents];
  }

  public get find() {
    return (id: string) => this.data.find(r => r.id === id);
  }

  public get user() {
    return this._user;
  }

  public get currentResearch() {
    const id = router.currentRoute.params.id;
    return this.data.find(research => research.id === id);
  }

  public get researchRespondents() {
    const id = router.currentRoute.params.id;
    return this.respondents.filter(respondent => respondent.rid === id);
  }

  public get targets() {
    return this.currentResearch && this.currentResearch.target;
  }

  public get customFilters() {
    let csvFilters = {} as any;
    if (!this.currentResearch || !this.currentResearch.customFields)
      return csvFilters;
    const { mwb, kl }: any =
      this.currentResearch && this.currentResearch.customFields;
    const customFields = _.unionBy(mwb, kl, 'label');

    // check if respondents have csv filter options
    for (const respondent of this.researchRespondents) {
      customFields.forEach((field: any) => {
        const fieldValue = (respondent as any)[field.label];

        if (fieldValue > ' ') {
          if (!csvFilters[respondent.target]) {
            csvFilters = { ...csvFilters, [respondent.target]: {} };
          }
          if (!csvFilters[respondent.target][field.label]) {
            csvFilters[respondent.target][field.label] = [];
          }
          csvFilters[respondent.target][field.label].push(fieldValue);
        }
      });
    }

    return csvFilters;
  }

  @Action({ rawError: true })
  public async bind() {
    await user.ready;

    if (this.user === user.id) return;

    const { company } = user.data!;

    const researchRef = user.admin
      ? firebase.col(`research`)
      : firebase.col(`research`).where('company', 'in', company);
    await firebase.bind(this, '_data', researchRef);

    const respondentRef = user.admin
      ? firebase.colGroup(`respondent`)
      : firebase.colGroup(`respondent`).where('company', 'in', company);
    await firebase.bind(this, '_respondents', respondentRef);

    if (!user.admin && webpack.FEATURES.demo_research) {
      const demoRef = firebase.col('research').where('company', '==', '<demo>');
      await firebase.bind(this, '_demo', demoRef);

      const demoRespondentRef = firebase
        .colGroup('respondent')
        .where('company', '==', '<demo>');
      await firebase.bind(this, '_demoRespondents', demoRespondentRef);
    }

    this.INITIALIZE(user.id);
  }

  @Action({ rawError: true })
  public async unbind() {
    if (!this._user) return;

    await firebase.unbind(this, '_data');
    await firebase.unbind(this, '_demo');
    await firebase.unbind(this, '_demoRespondents');
    await firebase.unbind(this, '_respondents');
    this.INITIALIZE('');
  }

  @Mutation
  public INITIALIZE(payload: string) {
    this._user = payload;
  }
}
