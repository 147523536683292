






























import { Vue, Component, Prop, Watch, Emit, Ref } from 'vue-property-decorator';
import { Permission } from '@app/models';
import _ from 'lodash';

@Component({
  name: 'app-menu-item',
})
export default class AppMenuItem extends Vue {
  @Prop() children?: any;
  @Prop() depth!: number;
  @Prop() icon?: string;
  @Prop() item!: any;
  @Prop() label!: string;
  @Prop() name!: string;
  @Prop() showChildren?: string;

  @Prop({ type: Boolean })
  public isMobile!: boolean;

  public get show() {
    if (
      Object.keys(this.children || {}).length &&
      !Object.keys(this.childrenToRender || {}).length
    )
      return false;

    return this.item.admin
      ? this.$user.admin
      : this.checkPermission(this.item.permission);
  }

  public get href() {
    return Object.keys(this.to).length
      ? this.$router.resolve(this.to).href
      : undefined;
  }

  public get to() {
    return this.children || this.label === 'sign-out'
      ? {}
      : { name: this.name, params: this.item.params };
  }

  public async handleClick(label: string) {
    if (label === 'sign-out') {
      this.$router.push('/');

      await this.$user.signOut();

      this.closeMenu();
    } else if (this.children) {
      this.$emit('toggle-children', label);
    } else {
      if (this.isMobile) this.closeMenu();
    }
  }

  public get childrenToRender() {
    return Object.keys(this.children || {}).reduce((acc, name) => {
      const item = this.children![name];
      if (!this.checkPermission(item.permission)) return acc;
      acc[name] = item;
      return acc;
    }, {} as any);
  }

  public checkPermission(permissions: Permission[]) {
    if (this.item.mobile && !this.isMobile && this.$user.online) return false;
    if (this.$user.admin || !permissions) return true;
    return permissions.every(p => this.$company.permission(p));
  }

  public closeMenu() {
    this.$el.closest('bce-side-bar')!.toggle(false);
  }
}
