import { User, Respondent } from '@app/models';
import fb from 'firebase/app';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { store } from '../store';

@Module({ dynamic: true, store, name: 'respondent', namespaced: true })
export class RespondentModule extends VuexModule {
  private _initialize: boolean = false;
  private _claims: User.Claims | undefined = undefined;
  private _results: any = {};
  private _respondent: Respondent | undefined = undefined;

  public get ready() {
    return ready;
  }

  public get respondentData() {
    return this._respondent;
  }

  public get done() {
    return this._respondent && this._respondent.status === 'done';
  }

  public get initialize() {
    return this._initialize;
  }

  public get claims() {
    return this._claims;
  }

  public get id() {
    return this.claims ? (this.claims as any).user_id : '';
  }

  public get rid() {
    return this.claims ? (this.claims as any).research : '';
  }

  public get cid() {
    return this.claims ? (this.claims as any).cid : '';
  }

  public get sub() {
    return this.claims ? (this.claims as any).sub : '';
  }

  public get formula() {
    return this.claims ? (this.claims as any).formula : '';
  }

  public get language() {
    return (this._respondent && this._respondent.language) || 'nl';
  }

  public get name() {
    return this.claims ? (this.claims as any).name : '';
  }

  public get target() {
    return this._respondent ? this._respondent.target : '';
  }

  public get gender() {
    return this._respondent ? this._respondent.gender : '';
  }

  public get results() {
    return this._results;
  }

  public get lastResult() {
    if (!this.results.length) return null;
    if (this.results.length === 1) return this.results[0].route;
    const lastResult = this.results.sort((a: any, b: any) => b.nr - a.nr)[0];
    return lastResult.route;
  }

  public get resultById() {
    return (id: string) =>
      this.results.find((result: any) => result.answer.id === id);
  }

  public get resultBydashboard() {
    return (id: string) =>
      this.results.find((result: any) => result.dashboard === id);
  }

  @Action({ rawError: true })
  public async signIn(payload: { token: string }) {
    const credentials = await firebase.signIn(payload.token);
    await this.bind(credentials.user!);
  }

  @Action({ rawError: true })
  public async signOut() {
    await ready;
    await this.unbind();
    await firebase.signOut();
  }

  @Action({ rawError: true })
  public async refreshToken() {
    const result = await firebase.refreshToken();
    if (result) this.CLAIMS(result.claims);
  }

  @Action({ rawError: true })
  public async bind(user: fb.User) {
    if (this.claims) return;

    // Retrieve claims from token
    const { claims } = await user.getIdTokenResult();

    if (!claims.research) return;
    this.CLAIMS(claims);

    const respondentPath = `research/${claims.research}/respondent/${claims.user_id}`;
    const respondentRef = firebase.doc(respondentPath);
    await firebase.bind(this, '_respondent', respondentRef);

    const resultsRef = firebase
      .col('results')
      .where('rid', '==', claims.research)
      .where('sub', '==', claims.sub);
    await firebase.bind(this, '_results', resultsRef);

    this.INITIALIZE(true);
  }

  @Action({ rawError: true })
  public async unbind() {
    if (!this.initialize) return;
    if (this.claims) this.CLAIMS(null);
    this.INITIALIZE(false);
  }

  @Mutation
  private CLAIMS(claims: any | null) {
    this._claims = claims ? claims : undefined;
  }

  @Mutation
  private INITIALIZE(payload: boolean) {
    this._initialize = payload;
  }
}

export const respondent = getModule(RespondentModule);

const ready = new Promise<void>(res => {
  firebase.onAuth(u => {
    if (!u) respondent.unbind().then(res);
    else respondent.bind(u).then(res);
  });
});
