import { validator } from '@bcase/core';

import { i18n } from '../store/modules/cms-module';
import { ISOdateToNormal } from './simple-functions';
import { PasswordValidation } from './password-validation';

// Setup validator translation
validator.translator = async ctx => {
  const { rule } = ctx;
  const { el } = ctx.meta;

  switch (rule) {
    case 'max':
    case 'min':
      return i18n.t(`validation-${rule}-${(el as any).type}`, {
        [rule]:
          'type' in el && el.type === 'date'
            ? ISOdateToNormal(ctx.args[0])
            : ctx.args[0],
      });
    case 'password':
      return i18n.t(ctx.message);
    case 'password-is':
      return i18n.t('should-match-repeated-password');
    default:
      return i18n.t('validation-' + rule);
  }
};

// Custom validator rules, note that no default messages are provided because
// they all go through the i18n translator anyway.

validator.rules.set('phone', async (value, args, meta) => {
  if (typeof value !== 'string') {
    const message = `[${meta.el}] Unsupported validation rule: phone`;
    throw new Error(message);
  }

  const regex =
    /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
  const valid = regex.test(value);
  const message = 'This field should contain a valid phone number.';
  return { valid, message };
});

validator.rules.set('research-invitation', async (value, args, meta) => {
  if (typeof value !== 'string') {
    const message = `[${meta.el}] Unsupported validation rule: phone`;
    throw new Error(message);
  }

  const valid = value.indexOf('{link}') >= 0; // && /{privacy(:[^}]+)?}/g.test(value);
  const message = 'The {link} and {privacy} mapping must be included.';
  return { valid, message };
});

validator.rules.set('password', async (value, args, meta) => {
  if (typeof value !== 'string') {
    const message = `[${meta.el}] Unsupported validation rule: password`;
    throw new Error(message);
  }

  const validator = new PasswordValidation(value as string);
  if (!validator.validateMin())
    return { valid: false, message: 'min-8-characters' };
  if (!validator.validateDigit())
    return { valid: false, message: 'min-1-number' };
  if (!validator.validateCharacters())
    return { valid: false, message: 'min-1-special-character' };

  return { valid: true, message: '' };
});

// Forward to the is rule, difference is that the translator will detect that it
// is a password validation rule. Allows the correct i18n label to be used.
validator.rules.set('password-is', (value, args, meta) => {
  const is = validator.rules.get('is');
  if (!is) {
    const message = `[${meta.el}] The 'is' validation rule is not available.`;
    throw new Error(message);
  }

  return is(value, args, meta);
});
