import nlFaq from './nl/faq.json';
import nlHomeFeature from './nl/home-feature.json';
import nlHomeStory from './nl/home-story.json';
import nlLabelPlain from './nl/label-plain.json';
import nlLabelRich from './nl/label-rich.json';
import nlLicense from './nl/license.json';
import nlMail from './nl/mail.json';

export const faq = { nl: nlFaq };
export const homeFeature = { nl: nlHomeFeature };
export const homeStory = { nl: nlHomeStory };
export const labelPlain = { nl: nlLabelPlain };
export const labelRich = { nl: nlLabelRich };
export const license = { nl: nlLicense };
export const mail = { nl: nlMail };

export const nl = {
  ...nlFaq,
  ...nlHomeFeature,
  ...nlHomeStory,
  ...nlLabelPlain,
  ...nlLabelRich,
  ...nlLicense,
  ...nlMail
};

type Language = typeof nl;

declare global {
  namespace Bce {
    interface I18nLanguage extends Language {}

    interface I18nLocaleMap {
      nl: 'nl';
    }
  }
}
