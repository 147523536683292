















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FormuleBoosterLoader extends Vue {
  @Prop({ type: String, default: '' })
  public title!: string;

  @Prop({ type: String, default: '' })
  public message!: string;

  @Prop({ type: String, default: 'light' })
  public mode!: string;
}
