import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';

import { store } from '../store';

type MenuState = HTMLBceSideBarElement['state'];

@Module({ dynamic: true, store, name: 'ui', namespaced: true })
export class UiModule extends VuexModule {
  private _menu: MenuState = undefined;

  public get menu() {
    return this._menu;
  }

  @Action({ rawError: true })
  public async setMenu(state: MenuState) {
    this.MENU(state);
  }

  @Mutation
  public MENU(state: MenuState) {
    this._menu = state;
  }
}
