import { AppRoles, Company, Invoice, Permission } from '@app/models';
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { router } from '../../utils/router';
import { store } from '../store';
import { fileManager } from './storage-module';
import { user } from './user-module';

@Module({ dynamic: true, store, name: 'company', namespaced: true })
export class CompanyModule extends VuexModule {
  private _app_roles: AppRoles | null = null;
  private _list: Company[] = [];
  private _invoices: Invoice[] = [];

  public get access() {
    const auth = user.id && company.data && company.data.auth[user.id];
    return auth ? auth.access : {};
  }

  public get appRoles() {
    return this._app_roles ? this._app_roles.roles : [];
  }

  public get categories() {
    return (this.data && this.data.categories) || [];
  }

  public get color() {
    return this.data && this.data.color;
  }

  public get color2() {
    return this.data && this.data.color2;
  }

  public get data() {
    const company =
      user.isRespondent || !user.complete
        ? user.company[0]
        : user.data && user.data.selectedCompany;
    return company && this.find(company);
  }

  public get find() {
    return (id: string) => this._list.find(c => c.id === id);
  }

  public get getInvoice() {
    return (researchId?: string) =>
      this.invoices.find(i => i.research === researchId);
  }

  public get id() {
    return this.data && this.data.id;
  }

  public get invoices() {
    return this._invoices;
  }

  public get license() {
    return (
      this.data &&
      (this.data.complete
        ? this.data.license.current
        : this.data.license.request)
    );
  }

  public get list() {
    return this._list;
  }

  public get logo() {
    return this.data && this.data.logo[0]
      ? fileManager.get(this.data.logo[0])
      : undefined;
  }

  public get name() {
    return this.data && this.data.name;
  }

  public get permission() {
    return (permission: Permission) => {
      return user.admin || this.permissions.indexOf(permission) >= 0;
    };
  }

  public get permissions() {
    const auth = user.id && company.data && company.data.auth[user.id];
    return (auth && auth.permissions) || [];
  }

  public get roles() {
    const auth = user.id && company.data && company.data.auth[user.id];
    return auth ? auth.roles : [];
  }

  @Action({ rawError: true })
  public async bind() {
    if (this.data) return;

    // Link company
    const collection = firebase.col('company');
    const companyRef = user.admin
      ? collection
      : collection.where('cid', 'in', user.company);
    await firebase.bind(this, '_list', companyRef.orderBy('name'));

    // Link app roles
    const rolesRef = firebase.doc('app/roles');
    await firebase.bind(this, '_app_roles', rolesRef);

    if (!user.isRespondent) {
      // Link invoices
      const invoiceRef = user.admin
        ? firebase.colGroup('invoice')
        : firebase.colGroup('invoice').where('company', 'in', user.company);
      await firebase.bind(this, '_invoices', invoiceRef);
    }
  }

  @Action({ rawError: true })
  public async select(id: string) {
    if (!user.id) return;
    await firebase.doc(`user/${user.id}`).update({ selectedCompany: id });

    const route = router.currentRoute;
    const { company_id } = route.params;
    if (company_id && company_id !== id) {
      const params = { ...route.params, company_id: id };
      router.push({ ...route, name: route.name || '', params });
    }
  }

  @Action({ rawError: true })
  public async unbind() {
    if (!this.data) return;

    await firebase.unbind(this, '_list');
    await firebase.unbind(this, '_invoices');
  }
}

export const company = getModule(CompanyModule);
